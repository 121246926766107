import React from "react"
import { Box, Typography, Tooltip } from "@mui/material"
import { CENTERED_FLEX_ROW } from "@leadbay/constants"
import { HighlightedFields } from "@components/display/LbDatatableCell/LbDatatableCell"

interface LbLeadInfosItemsProps {
  index: number
  icon: React.ReactNode
  type: string
  value: string
  last_website_clicked_at?: string
  handleWebsiteClickInteraction: () => void
  handleOpenLocationOnGoogleMaps: (value: string) => void
  handleCopyToClipboard: (value: string) => void
  formatUrl: (url: string) => string
  formatDateSmall: (date: string) => string
  highlightedFields?: ("LOCATION" | "SECTOR" | "SIZE")[]
}

export const LbLeadInfosItems: React.FC<LbLeadInfosItemsProps> = ({
  index,
  icon,
  type,
  value,
  last_website_clicked_at,
  handleWebsiteClickInteraction,
  handleOpenLocationOnGoogleMaps,
  handleCopyToClipboard,
  formatUrl,
  formatDateSmall,
  highlightedFields,
}) => {
  const getHighlightedStyles = (type: HighlightedFields) => {
    const isHighlighted = highlightedFields?.some((field) => field === type)

    return {
      fontFamily: isHighlighted ? "BasementGrotesque" : "Hanken Grotesk",
      fontSize: isHighlighted ? "0.8rem" : "0.9rem",
    }
  }
  return (
    <Box
      key={index}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        my: 2,
        "&:last-child": {
          marginBottom: 0,
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>

      {type === "WEBSITE" ? (
        <Box sx={{ ...CENTERED_FLEX_ROW, gap: 1 }}>
          <a
            onClick={handleWebsiteClickInteraction}
            href={formatUrl(value)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="subtitle2" fontWeight="bold">
              {value}
            </Typography>
          </a>

          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontWeight="bold"
          >
            -
          </Typography>

          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontWeight="bold"
          >
            {last_website_clicked_at
              ? "Checked on " + formatDateSmall(last_website_clicked_at)
              : "Not checked yet"}
          </Typography>
        </Box>
      ) : type === "LOCATION" ? (
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          onClick={() => handleOpenLocationOnGoogleMaps(value)}
          sx={{
            ...getHighlightedStyles(type as HighlightedFields),
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {value}
        </Typography>
      ) : (
        <Tooltip title={`Copy to clipboard`}>
          <Typography
            onClick={() => handleCopyToClipboard(value)}
            variant="subtitle1"
            fontWeight="bold"
            sx={{
              ...getHighlightedStyles(type as HighlightedFields),
              cursor: "pointer",
            }}
          >
            {value}
          </Typography>
        </Tooltip>
      )}
    </Box>
  )
}
