import React from "react"
import { Box, Typography, IconButton } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { LbWishlistFiltersCard } from "@components/inputs/LbWishlistFiltersCard/LbWishlistFiltersCard"

interface LbLeadInfosKeywordsProps {
  showAllKeywords: boolean
  computedKeywords: any[]
  firstKeywords: any[]
  handleToggleShowAllKeywords: () => void
}

export const LbLeadInfosKeywords: React.FC<LbLeadInfosKeywordsProps> = ({
  showAllKeywords,
  computedKeywords,
  firstKeywords,
  handleToggleShowAllKeywords,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          fontWeight={900}
          variant="subtitle1"
          fontFamily="Hanken Grotesk"
          color="text.secondary"
          sx={{ mb: 2, mt: 3 }}
        >
          Keywords
        </Typography>

        <Box
          onClick={handleToggleShowAllKeywords}
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <Typography variant="caption" color="text.secondary">
            {showAllKeywords ? "Show less" : "Show all"}
          </Typography>

          <IconButton size="small">
            {showAllKeywords ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>

      <LbWishlistFiltersCard
        readonly
        variant="outlined"
        py={0}
        filterItem={{
          name: "Size",
          score: 0,
          items: showAllKeywords ? computedKeywords : firstKeywords,
        }}
      />
    </>
  )
}
