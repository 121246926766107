import React, { useRef } from "react"
import { Box, Typography, Tooltip, Chip, Badge, Popover } from "@mui/material"
import AnimatedNumber from "@crossfox/react-animated-number"
import Info from "@mui/icons-material/Info"
import { LbUpdateLeadStatus } from "../LbUpdateLeadStatus"

interface LeadInfoBoxProps {
  state: any
  localScore: number
  leadData: any
  leadIdPredictStatus: any
  showPredictedStatusExplanation: boolean
  handleTogglePredictedStatusExplanation: () => void
}

export const LbLeadInfosStatus: React.FC<LeadInfoBoxProps> = ({
  state,
  localScore,
  leadData,
  leadIdPredictStatus,
  showPredictedStatusExplanation,
  handleTogglePredictedStatusExplanation,
}) => {
  const hasExplanation =
    leadIdPredictStatus?.explanations !== undefined &&
    leadIdPredictStatus?.explanations?.length > 0

  const explanationIconRef = useRef<SVGSVGElement>(null)

  return (
    <Box sx={{ width: "100%" }}>
      {state?.status !== "WON" &&
        state?.status !== "LOST" &&
        localScore !== 0 && (
          <>
            <Typography
              fontWeight={900}
              variant="subtitle1"
              fontFamily="Hanken Grotesk"
              color="text.secondary"
              sx={{ mb: 1 }}
            >
              Score
            </Typography>

            <Box sx={{ display: "flex", alignItems: "flex-end", ml: -1.5 }}>
              <Tooltip title="This score shows how closely your lead matches your ideal customer profile (ICP), based on the leads you've imported and the parameters you've set">
                <Typography
                  variant="h4"
                  fontFamily="BasementGrotesque"
                  sx={{ mb: 0.5, lineHeight: 0.9 }}
                >
                  <AnimatedNumber
                    rate={100}
                    value={localScore}
                    duration={400}
                  />
                </Typography>
              </Tooltip>
            </Box>
          </>
        )}

      {state?.status ? (
        <Box
          sx={{ display: "flex", alignItems: "center", mt: localScore ? 3 : 0 }}
        >
          <Typography
            fontWeight={900}
            variant="subtitle1"
            fontFamily="Hanken Grotesk"
            color="text.secondary"
            marginRight={2}
            lineHeight={1}
          >
            Status
          </Typography>

          <Chip
            label={state?.status}
            color={
              state?.status === "WON"
                ? "info"
                : state?.status === "LOST"
                  ? "warning"
                  : "default"
            }
            sx={{ fontWeight: 700 }}
          />

          <LbUpdateLeadStatus leadId={leadData.id as string} />
        </Box>
      ) : leadIdPredictStatus?.status ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 1,
            mt: 3,
          }}
        >
          <Badge
            invisible={!leadIdPredictStatus?.changed}
            badgeContent="new !"
            color="primary"
          >
            <Typography
              fontWeight={900}
              variant="subtitle1"
              fontFamily="Hanken Grotesk"
              color="text.secondary"
            >
              Predicted status
            </Typography>
          </Badge>

          <Tooltip
            title={
              leadIdPredictStatus?.changed
                ? "Status prediction changed recently"
                : "Status prediction"
            }
          >
            <Chip
              label={leadIdPredictStatus?.status}
              color={
                leadIdPredictStatus?.status === "WON"
                  ? "info"
                  : leadIdPredictStatus?.status === "LOST"
                    ? "warning"
                    : leadIdPredictStatus?.status === "WANTED"
                      ? "default"
                      : "default"
              }
              sx={{ fontWeight: 700 }}
              deleteIcon={
                hasExplanation ? <Info ref={explanationIconRef} /> : undefined
              }
              onDelete={
                hasExplanation
                  ? handleTogglePredictedStatusExplanation
                  : undefined
              }
            />
          </Tooltip>
        </Box>
      ) : null}

      {!state?.status && (
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Typography
            fontWeight={900}
            variant="subtitle1"
            fontFamily="Hanken Grotesk"
            color="text.secondary"
            lineHeight={1}
          >
            Update status
          </Typography>

          <LbUpdateLeadStatus leadId={leadData.id as string} />
        </Box>
      )}

      {hasExplanation && (
        <Popover
          open={showPredictedStatusExplanation}
          anchorEl={explanationIconRef.current}
          onClose={handleTogglePredictedStatusExplanation}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            gap={1}
            p={2}
            width={300}
          >
            {leadIdPredictStatus.explanations
              ?.slice(0, 3)
              .map((explanation: string) => (
                <Typography
                  key={explanation}
                  variant="caption"
                  color="text.secondary"
                  sx={{ fontStyle: "italic" }}
                >
                  {explanation}
                </Typography>
              ))}
          </Box>
        </Popover>
      )}
    </Box>
  )
}
