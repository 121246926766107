import { Box, Typography, IconButton, Tooltip } from "@mui/material"
import { ArrowBack, ChevronLeft } from "@mui/icons-material"
import { LbDislikeButton } from "@components/inputs/LbUnlikeButton/LbUnlikeButton"
import { LbUntrashButton } from "@components/inputs/LbUntrashButton/LbUntrashButton"
import { upperCaseFirstLetter } from "@leadbay/utils"
import { WEB_APP_ROUTES } from "@leadbay/constants"
import {
  WishlistViewMode,
  selectCommonsState,
} from "@leadbay/state/slices/commonsSlice"
import { formatDate } from "date-fns"
import { type GridRowParams } from "@mui/x-data-grid"
import { type Lead1 } from "@leadbay/state/api"
interface LbLeadInfosHeaderProps {
  logo?: string
  name?: string
  website?: string
  origin?: { type: string; date: string }
  windowLocation: Location
  handleWebsiteClickInteraction: () => void
  handleClose: () => void
  handleNavigateBack: () => void
  leadHistory: any[]
  isTrashScreen: boolean
  isFavoritesScreen: boolean
  leadData: GridRowParams<Lead1>
  description?: string
  descriptionSource?: string
  wishlistViewMode: WishlistViewMode
}

const formatUrl = (url: string): string =>
  url.startsWith("http://") ||
  url.startsWith("https://") ||
  url.startsWith("www.")
    ? url
    : `//${url}`

const LbLeadInfosHeader: React.FC<LbLeadInfosHeaderProps> = ({
  logo,
  name,
  website,
  origin,
  windowLocation,
  handleWebsiteClickInteraction,
  handleClose,
  handleNavigateBack,
  leadHistory,
  isTrashScreen,
  isFavoritesScreen,
  leadData,
  description,
  descriptionSource,
  wishlistViewMode,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
              }}
            >
              {logo && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={logo}
                    alt={name}
                    style={{
                      objectFit: "contain",
                      height: "35px",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              )}

              {name &&
                (website ? (
                  <a
                    onClick={handleWebsiteClickInteraction}
                    href={formatUrl(website)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography
                      variant="h6"
                      fontFamily="BasementGrotesque"
                      sx={{
                        textTransform: "uppercase",
                        lineHeight: 1.2,
                        textDecorationLine: "underline",
                      }}
                    >
                      {name}
                    </Typography>
                  </a>
                ) : (
                  <Typography
                    variant="h6"
                    fontFamily="BasementGrotesque"
                    sx={{
                      textTransform: "uppercase",
                      lineHeight: 1.2,
                    }}
                  >
                    {name}
                  </Typography>
                ))}
            </Box>

            {origin && (
              <Typography
                sx={{ mt: 0.5 }}
                color="text.secondary"
                variant="caption"
              >
                {origin.type === "CRM" && "Imported from " + origin.type}
                {origin.type === "EXPORTED" &&
                  upperCaseFirstLetter(
                    origin.type.toLocaleLowerCase() + " from whishlist",
                  )}{" "}
                on {formatDate(origin.date, "y-m-d 'at' H:mm:ss")}
              </Typography>
            )}
          </Box>

          {windowLocation.pathname === WEB_APP_ROUTES.APP &&
            [WishlistViewMode.MONITOR, WishlistViewMode.DISCOVER].includes(
              wishlistViewMode,
            ) && (
              <Tooltip title="back to filters">
                <IconButton
                  onClick={handleClose}
                  color="primary"
                  sx={{ mt: "-2px", mr: -1 }}
                  disableFocusRipple
                  disableRipple
                >
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            )}
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {leadHistory.length > 1 && (
              <IconButton
                onClick={handleNavigateBack}
                color="primary"
                size="small"
              >
                <ChevronLeft />
              </IconButton>
            )}
          </Box>

          {isTrashScreen && <LbUntrashButton leadId={leadData.id as string} />}

          {isFavoritesScreen && (
            <LbDislikeButton leadId={leadData.id as string} />
          )}
        </Box>
      </Box>

      {description && (
        <Typography sx={{ mt: logo ? 0 : 1 }} variant="subtitle2">
          {description}
        </Typography>
      )}

      {descriptionSource && (
        <Typography sx={{ mt: 1 }} color="text.secondary" variant="caption">
          source: {descriptionSource}
        </Typography>
      )}
    </Box>
  )
}

export default LbLeadInfosHeader
