import React from "react"
import { Box, Typography } from "@mui/material"
import { CrunchbaseLogo } from "@components/logos/CrunchbaseLogo"
import { FacebookLogo } from "@components/logos/FacebookLogo"
import { InstagramLogo } from "@components/logos/InstagramLogo"
import { LinkedinLogo } from "@components/logos/LinkedinLogo"
import { TiktokLogo } from "@components/logos/TiktokLogo"
import { XLogo } from "@components/logos/XLogo"
import { LeadSocialUrls } from "@leadbay/state/api"

interface LbLeadInfosSocialProps {
  socialUrls: LeadSocialUrls
}

export const LbLeadInfosSocial: React.FC<LbLeadInfosSocialProps> = ({
  socialUrls,
}) => {
  return (
    <Box>
      <Typography
        fontWeight={900}
        variant="subtitle1"
        fontFamily="Hanken Grotesk"
        color="text.secondary"
        sx={{ mb: 2 }}
      >
        Social
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          my: 2,
        }}
      >
        {Object.entries(socialUrls).map(([socialName, socialUrl]) => (
          <Box key={socialName}>
            <a href={socialUrl} target="_blank" rel="noopener noreferrer">
              <Box
                sx={{
                  height: "100%",
                }}
              >
                {socialName === "crunchbase" && (
                  <CrunchbaseLogo fill="#0288D1" />
                )}
                {socialName === "facebook" && <FacebookLogo fill="#0866FF" />}
                {socialName === "instagram" && <InstagramLogo fill="#E4405F" />}
                {socialName === "linkedin" && <LinkedinLogo fill="#0A66C2" />}
                {socialName === "tiktok" && <TiktokLogo />}
                {socialName === "twitter" && <XLogo fill="#000000" />}
              </Box>
            </a>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
