import React from "react"
import { Box, Typography, Chip } from "@mui/material"
import {
  selectCommonsState,
  setDrawerData,
  setLeadHistory,
} from "@leadbay/state/slices/commonsSlice"
import {
  Lead1,
  useGetLensesByLensIdLeadsAndLeadIdSimilarPastQuery,
} from "@leadbay/state/api"
import { useAppDispatch, useAppSelector } from "@leadbay/state/hooks"

interface LbLeadInfosSimilarProps {
  location?: string
  localDatabaseId: string
}

export const LbLeadInfosSimilar: React.FC<LbLeadInfosSimilarProps> = ({
  location,
  localDatabaseId,
}) => {
  const { leadHistory, currentLensId } = useAppSelector(selectCommonsState)

  const {
    data: leadIdSimilarPast,
    isFetching: leadsByLeadIdSimilarPastIsFetching,
  } = useGetLensesByLensIdLeadsAndLeadIdSimilarPastQuery(
    {
      lensId: currentLensId,
      leadId: localDatabaseId,
    },
    {
      skip: !currentLensId,
    },
  )

  const dispatch = useAppDispatch()

  const handleLeadNavigate = (similarLead: Lead1) => {
    try {
      const id = similarLead.id

      dispatch(setLeadHistory([...leadHistory, similarLead]))

      const size = similarLead.size?.min
        ? [similarLead?.size?.min, similarLead?.size?.max]
        : similarLead?.size

      dispatch(
        setDrawerData({
          id,
          row: {
            databaseId: id,
            score: similarLead?.score,
            highlighted_fields: similarLead?.highlighted_fields,
            website: similarLead?.website,
            location: location,
            size: size ?? null,
            state: similarLead?.state,
            sector: similarLead?.sector,
            type: similarLead?.type,
            keywords: similarLead?.keywords?.map((kw) => ({
              label: kw?.keyword,
              highlighted: kw?.highlighted,
            })),
            logo: similarLead?.logo,
            name: similarLead?.name,
            description: similarLead?.description,
            description_source: similarLead?.description_source,
          },
          columns: [],
        }),
      )
    } catch (error) {
      console.error(`Error navigating to similar lead: ${error}`)
    }
  }

  return (
    <Box>
      <Typography
        fontWeight={900}
        variant="subtitle1"
        fontFamily="Hanken Grotesk"
        color="text.secondary"
        sx={{ mb: 1 }}
      >
        Similar past leads
      </Typography>

      {leadIdSimilarPast?.map((similarLead) => (
        <Chip
          onClick={() => {
            handleLeadNavigate(similarLead)
          }}
          key={similarLead.id}
          label={similarLead.name}
          color={
            similarLead.state?.status === "WON"
              ? "info"
              : similarLead.state?.status === "LOST"
                ? "warning"
                : similarLead.state?.status === "WANTED"
                  ? "default"
                  : "default"
          }
          sx={{ mr: 1, mb: 1, fontWeight: 700 }}
        />
      ))}
    </Box>
  )
}
function dispatch(arg0: any) {
  throw new Error("Function not implemented.")
}
